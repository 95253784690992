// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bookings-js": () => import("./../../../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fees-js": () => import("./../../../src/pages/fees.js" /* webpackChunkName: "component---src-pages-fees-js" */),
  "component---src-pages-health-365-js": () => import("./../../../src/pages/health365.js" /* webpackChunkName: "component---src-pages-health-365-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */)
}

